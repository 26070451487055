<template>
  <div class="admin-users">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
      <div>
        <i class="icon-button" :class="$config.icons.general.refresh" v-b-tooltip title="Refresh" @click="refreshList"></i>
        <i class="mx-1 icon-button" :class="$config.icons.user.add" v-b-tooltip title="Add a User" @click="createUser"></i>
        <i class="icon-button" :class="$config.icons.general.export" v-b-tooltip title="Export" @click="exportUserList"></i>
        <router-link to="/admin/users/bulkadd"><i class="mx-1 icon-button" :class="$config.icons.user.bulkAdd" v-b-tooltip title="Upload Users"></i></router-link>
<!--        <i class="fa fa-user-times mx-1 icon-button" v-b-tooltip title="Delete Selected Users" @click="deleteSelected"></i>-->
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>User Admin</h2></b-card-header>
          <b-card-body>
            <admin-user-list v-on:change="selectChange" ref="adminUserList"></admin-user-list>
          </b-card-body>
      </b-card>
    </div>
    <!--    Create User Modal Template-->
    <b-modal id="modal-create-user" centered class="modal-content" size="xl" hide-footer title="Create User">
      <admin-edit-user is-modal modal="modal-create-user" v-on:save="refreshList()"></admin-edit-user>
    </b-modal>
    <!--    Data Exporter Template-->
    <b-modal id="modal-export" centered class="modal-content" size="xl" hide-footer title="Export Data">
      <task-progress v-if="exportUserTaskId" :task_id="exportUserTaskId" @finish="exportTaskComplete"></task-progress>
      <data-exporter v-if="exportData" :data="exportData" ></data-exporter>
    </b-modal>
  </div>
</template>

<script>

import AdminUserList from '../../components/admin/AdminUserList'
import AdminEditUser from '../../components/admin/AdminEditUser'
import DataExporter from '@/components/DataExporter'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import TaskProgress from '@/components/stats/TaskProgress.vue'

export default {
  name: 'admin-users',
  components: {
    TaskProgress,
    DataExporter,
    AdminUserList,
    AdminEditUser
  },
  data: function () {
    return {
      selectedUsers: [],
      exportData: null,
      exportUserTaskId: null
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    selectChange: function (newSelection) {
      this.selectedUsers = newSelection
    },
    createUser: function () {
      this.$bvModal.show('modal-create-user')
    },
    refreshList: function () {
      this.$refs.adminUserList.refreshUserList()
    },
    exportUserList: async function () {
      this.$bvModal.show('modal-export')
      let fields = [
        'user_id',
        'name',
        'email',
        'admin',
        'activated',
        'last_login',
        'mobile_number',
        'stripe_id',
        'meets_subscription_reqs',
        'subscriptions',
        'device_count'
      ]
      let response = await DataProvider.adminGetUserList(null, null, fields)
      if (response.success) {
        this.exportUserTaskId = response.data.task_created.id
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    exportTaskComplete (status, data) {
      console.log('Task complete: ', data)
      if (status === 'COMPLETE') {
        this.exportData = Object.values(data)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-users {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

</style>
